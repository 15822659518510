<template lang="pug">
    li#cardInformacao
        router-link(:to="{ name: contents.browsers[0].module, params: { slug: pageSlug }, query: { backTo } }", v-if="!contents.isExternal && contents.browsers")
            Imagem(:contents="contents.image").icon
            h4 {{ contents.name.pt }}
        a(:href="contents.link.pt", target="_blank", rel="noopener noreferrer", v-else="contents.isExternal && contents.link")
            Imagem(:contents="contents.image").icon
            h4 {{ contents.name.pt }}
</template>

<script>
import { components, props } from '@/mixins/components'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import qs from 'qs'

export default {
  name: 'component-card-informacao',
  props,
  components,
  data () {
    return {
      icons: {
        faArrowUpRightFromSquare
      }
    }
  },
  computed: {
    browsers () {
      return this.contents.browsers
    },
    pageSlug () {
      if (this.browsers) { return this.browsers[0].slug }
      return null
    },
    backTo () {
      return qs.stringify({
        to: { name: 'Pagina', params: this.$route.params },
        page: 'à Pesquisa e Informações'
      })
    }
  }
}
</script>

<style lang="stylus" scoped src="./CardInformacao.styl"></style>
